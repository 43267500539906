<template>
  <div class="field photo-date has-addons">
    <p class="control" v-if="isComparisonMode">
      <button :class="leftSideClass" @click="selectSide('left')">
        <span class="icon is-small">
          <i class="far fa-image"></i>
        </span>
        <span>{{ comparisonPhotoDate }}</span>
      </button>
    </p>
    <p class="control">
      <button
        v-if="isComparisonMode"
        class="button"
        @click="switchComparisonMode"
        :title="comparisonModeTitle"
      >
        <span class="icon">
          <i :class="comparisonModeIcon"></i>
        </span>
      </button>
    </p>
    <p class="control">
      <button :class="rightSideClass" @click="selectSide('right')">
        <span>{{ currentPhotoDate }}</span>
        <span class="icon is-small">
          <i class="far fa-image"></i>
        </span>
      </button>
    </p>
    <p class="control">
      <button
        v-if="!isComparisonMode"
        :class="bookmarkButtonClass"
        :title="bookmarkButtonTitle"
        @click="bookmark"
      >
        <span class="icon">
          <i class="fas fa-bookmark"></i>
        </span>
      </button>
    </p>
    <p class="control">
      <button
        v-if="!isComparisonMode"
        :title="$t('navbar.share.download')"
        class="button"
        @click="download"
      >
        <span class="icon">
          <i class="fas fa-download"></i>
        </span>
      </button>
    </p>
    <p class="control">
      <button
        v-if="!isComparisonMode"
        :title="$t('navbar.share.share')"
        class="button"
        @click="photoShare"
      >
        <span class="icon">
          <i class="fas fa-share-alt"></i>
        </span>
      </button>
    </p>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState([
      "currentPhoto",
      "user",
      "modals",
      "selectedSide",
      "comparisonPhoto",
    ]),
    // ...mapGetters(["selectedCamera"]),

    comparisonModeIcon() {
      return this.$route.name === "Comparison"
        ? "fas fa-columns"
        : "fas fa-sync-alt";
    },
    comparisonModeTitle() {
      return this.$route.name === "Comparison"
        ? this.$i18n.t("viewButtonGroup.compare")
        : this.$i18n.t("viewButtonGroup.syncView");
    },
    bookmarkButtonClass() {
      return this.currentPhoto.bookmark && this.currentPhoto.bookmark.id>0
        ? this.currentPhoto.bookmark.type === "general"
          ? ["is-link", "button"]
          : ["is-danger", "button"]
        : "button";
    },
    bookmarkButtonTitle() {
      return this.currentPhoto.bookmark && this.currentPhoto.bookmark.id
        ? this.currentPhoto.bookmark.title
        : this.$i18n.t("navbar.share.bookmark");
    },
    leftSideClass() {
      return this.isComparisonMode && this.selectedSide === "left"
        ? ["button", "is-link"]
        : "button";
    },
    rightSideClass() {
      return this.isComparisonMode && this.selectedSide === "right"
        ? ["button", "is-link"]
        : "button";
    },
    isComparisonMode() {
      return this.$route.name === "Comparison" || this.$route.name === "Sync";
    },
    shareModal() {
      return this.modals.photoShare;
    },
    bookmarkModal() {
      return this.modals.bookmarkForm;
    },
    currentRoute() {
      return this.$route.name;
    },
    currentPhotoDate() {
      if (this.currentPhoto.timestamp) {
        var locale = "en-US"
        if (this.comparisonPhoto.timezone === 'Europe/Istanbul') {
          locale = "tr-TR"
        }
        return new Intl.DateTimeFormat(locale, {
          timeZone: this.currentPhoto.timezone,
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(this.currentPhoto.timestamp * 1000));
      }
      return "Select a photo";
    },
    comparisonPhotoDate() {
      if (this.comparisonPhoto.timestamp) {
        var locale = "en-US"
        if (this.comparisonPhoto.timezone === 'Europe/Istanbul') {
          locale = "tr-TR"
        }
        return new Intl.DateTimeFormat(locale, {
          timeZone: this.comparisonPhoto.timezone,
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(this.comparisonPhoto.timestamp * 1000));
      }
      return "Select a photo";
    },
  },
  methods: {
    ...mapMutations([
      "SET_SELECTED_SIDE",
      "SET_MODAL_STATE_PHOTO_SHARE",
      "SET_MODAL_STATE_BOOKMARK",
    ]),
    selectSide(side) {
      this.SET_SELECTED_SIDE(side);
    },
    switchComparisonMode() {
      if (this.$route.name === "Comparison") {
        this.$router.push({ name: "Sync" });
      } else {
        this.$router.push({ name: "Comparison" });
      }
    },
    bookmark() {
      if (!this.user.permissions.includes(this.bookmarkModal.permission)) {
        this.$showToastWithType(
          this.$t("message.error.noFunctionPermission"),
          "error"
        );
        return;
      }
      this.SET_MODAL_STATE_BOOKMARK(true);
    },
    download() {
      this.$store.dispatch("download", this.currentPhoto);
    },
    photoShare() {
      if (!this.user.permissions.includes(this.shareModal.permission)) {
        this.$showToastWithType(
          this.$t("message.error.noFunctionPermission"),
          "error"
        );
        return;
      }
      this.SET_MODAL_STATE_PHOTO_SHARE(true);
    },
  },
};
</script>

<style scoped>
.side-selector {
  position: absolute;
  top: -20px;
  left: calc(50% - 165px);
  z-index: 29;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  /* opacity: 0.5; */
  /* transition: opacity 1s; */
}
.photo-date {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 29;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  /* opacity: 0.5; */
  /* transition: opacity 1s; */
}
</style>

<template>
    <div>
      <Navbar />
      <PaymentForm v-if="isPaymentFormActive"/>
      <section class="section has-background-white" style="min-height: 100vh;">
        <div class="card">
        <h1 class="title">{{$t('creditCards.title')}}</h1>
        <div class="field is-grouped" style="justify-content: flex-end;">
            <p class="control">
            <button
                class="button is-link"
                @click="openStoreCardForm()"
            >
                <span class="icon"><i class="fas fa-plus"></i></span>
            </button>
            </p>
        </div>
      
        <table style="margin-top: 15px;" class="table is-fullwidth is-bordered is-striped is-hoverable">
          <thead>
            <th>{{$t('creditCards.last_4')}}</th>
            <th>{{$t('creditCards.type')}}</th>
            <th></th>
          </thead>
          <tbody v-if="creditCards.length">
            <tr v-for="creditCard in creditCards" :key="creditCard.id">
              <td>{{ creditCard.last_4 }}</td>
              <td>{{ creditCard.schema }}</td>
              <td v-if="creditCard.is_default">{{$t('creditCards.is_default')}}</td>
              <td v-if="!creditCard.is_default">
              <div class="field is-grouped">
                <p class="control">
                  <button
                    class="button is-small is-link is-outlined"
                    @click="setDefault(creditCard.last_4)"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-check"></i>
                    </span>
                  </button>
                </p>
                <p class="control">
                  <button
                    :title="$t('camera.delete')"
                    class="button is-small is-danger is-outlined"
                    @click="remove(creditCard.last_4)"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-trash"></i>
                    </span>
                  </button>
                </p>
              </div>
            </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td></td>
              <td class="has-text-centered" colspan="6">{{ $t("creditCards.not_found") }}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </section>  
     
    </div>
  </template>  
  <script>
  import Navbar from "@/components/NavBar.vue";
  import PaymentForm from "@/components/PaymentForm.vue";
  import { mapState,mapMutations} from "vuex";
  
  export default {
    name: "CreditCards",
    data() {
      return {
        creditCards: [],
      };
    },
    components: {
     Navbar, PaymentForm
    },
    methods: {
      convertDate(timestamp) {
        const dateObject = new Date(timestamp);
        const formattedTime = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const formattedDate = dateObject.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
        return `${formattedTime} ${formattedDate}`;
      },
      ...mapMutations([
      "SET_PAYMENTFORM_CAMERA_COUNT",
      "SET_PAYMENTFORM_ISACTIVE",
      "SET_PAYMENTFORM_SELECTED_PACKAGE_ID",
      "SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE",
      
    ]),
    openStoreCardForm() {
        this.SET_PAYMENTFORM_CAMERA_COUNT(0);    
        this.SET_PAYMENTFORM_ISACTIVE(true);
        this.SET_PAYMENTFORM_SELECTED_PACKAGE_ID(4);
      },
    remove(last_4){

        this.$http.delete("credit-card/"+last_4,null);
        
        setTimeout(()=> {
            this.$http.get("credit-cards").then((res) => {
                    this.creditCards = res.data;
                });
        }
        ,500);
    },
    setDefault(last_4){
        let formData = new FormData();
        formData.append("last_4", last_4);
        this.$http.put("selected-credit-card",formData);
        
        setTimeout(()=> {
            this.$http.get("credit-cards").then((res) => {
                    this.creditCards = res.data;
                });
        }
        ,500);
    },

    },
    computed: {
      ...mapState({
        isPaymentFormActive: (state) => state.paymentForm.isActive,
      }),
    },
    mounted() {
        this.$http.get("credit-cards").then((res) => {
            setTimeout(() => {
              this.creditCards = res.data;
             
            }, 100);
      });
      this.SET_PAYMENTFORM_ISACTIVE(false);
      this.SET_PAYMENTFORM_SELECTED_PACKAGE_ID(0);
      this.SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE("");
    },
  };
  </script>

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueI18n from "vue-i18n";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { Auth0Plugin } from "./auth0-plugin";


import http from "@/plugins/http";

import LogRocket from "logrocket";

if (process.env.NODE_ENV === "production") {
  LogRocket.init("2fgkyb/natilon-web-app");
}


Vue.use(http);
Vue.use(VueI18n);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

Vue.use(Auth0Plugin, {
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetPath
        ? appState.targetPath
        : window.location.pathname
    );
  },
});

Vue.prototype.$showToast = function(message) {
  store.state.toast.isActive = true
  store.state.toast.message = message
  setTimeout(() => {
    store.state.toast.isActive = false
  }, 1500);
};

Vue.prototype.$showToastWithType = function(message,type) {
  store.state.toast.type = type
  store.state.toast.isActive = true
  store.state.toast.message = message
  setTimeout(() => {
    store.state.toast.isActive = false
    store.state.toast.type = "info"
  }, 1500);
};

import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "./assets/main.scss";

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: store.state.user.language,
  fallbackLocale: "en",
  messages: {
    en: require("./locales/en.json"),
    tr: require("./locales/tr.json"),
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

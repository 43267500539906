<template>
  <section class="section" style="min-height: 100vh">
    <div class="level">
      <div class="level-left">
        <h1 class="title">{{ $t("camera.cameraListPageHeader") }}</h1>
      </div>
      <div class="level-right">
        <div class="field is-grouped">
          <p class="control">
            <button
              :title="$t('camera.create')"
              class="button is-link"
              @click="openForm(null)"
            >
              <span class="icon"><i class="fas fa-plus"></i></span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <table class="table is-fullwidth is-bordered is-striped is-hoverable mt-5">
      <thead>
        <th>{{ $t("camera.name") }}</th>
        <th></th>
      </thead>
      <tbody v-if="cameras.length && !isLoading">
        <tr v-for="camera in cameras" :key="camera.id">
          <td>{{ camera.name }}</td>
          <td>
            <div class="field is-grouped">
              <p class="control">
                <button
                  :title="$t('camera.edit')"
                  class="button is-small is-link is-outlined"
                  @click="openForm(camera)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-edit"></i>
                  </span>
                </button>
              </p>
              <p class="control">
                <button
                  :title="$t('camera.delete')"
                  class="button is-small is-danger is-outlined"
                  @click="remove(camera.id)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-trash"></i>
                  </span>
                </button>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoading">
        <tr>
          <td></td>
          <td class="has-text-centered" colspan="6">
            {{ $t("camera.listLoading") }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td></td>
          <td class="has-text-centered" colspan="6">
            {{ $t("camera.noData") }}
          </td>
        </tr>
      </tbody>
    </table>
    <transition name="fade">
      <components
        :is="formType"
        v-if="isFormActive"
        @close="closeForm"
        @switchForm="switchFormHandler"
        :camera="camera"
      />
    </transition>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CameraSettingsPro from "@/components/CameraSettingsPro.vue";
import CameraSettingsOnvif from "@/components/CameraSettingsOnvif.vue";
import SelectCameraType from "@/components/SelectCameraType.vue";
import CameraSettingsHikvision from "./CameraSettingsHikvision.vue";

export default {
  name: "CameraList",
  data() {
    return {
      cameraConnectionTypes: [],
      camera: {},
      isFormActive: false,
      page: 1,
      size: 10,
      isLoading: false,
      checkOnvifConnButtonTitle: "",
      checkOnfivConnectionLoading: false,
      formValidation: false,
      formType: "SelectCameraType",
    };
  },
  components: {
    CameraSettingsPro,
    CameraSettingsOnvif,
    CameraSettingsHikvision,
    SelectCameraType,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      cameras: (state) => state.cameras,
      companies: (state) => state.companies,
      projects: (state) => state.projects,
    }),
  },
  methods: {
    ...mapActions(["refreshState"]),
    switchFormHandler(form) {
      this.formType = form;
    },
    openForm(camera) {
      if (camera != null) {
        if (camera.version == 1) {
          this.formType = "CameraSettingsPro";
        } else if (camera.version == 2) {
          this.formType = "CameraSettingsOnvif";
        } else if (camera.version == 3) {
          this.formType = "CameraSettingsHikvision";
        }
        this.camera = camera;
      } else {
        this.camera = {
          id: 0,
          name: "",
          ip: "",
          port: 0,
          username: "",
          password: "",
          channel: "",
          project_id: this.projects[0].id,
          project_name: "",
          company_id: 0,
          company_name: "",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          serial_number: "",
          properties: {}
        };
      }

      this.isFormActive = true;
    },
    closeForm() {
      this.isFormActive = false;
      this.formType = "SelectCameraType";
    },
    validateForm() {
      if (this.camera.id == "onvif") {
        if (
          this.camera.name == "" ||
          this.camera.ip == "" ||
          this.camera.username == "" ||
          this.camera.password == "" ||
          this.camera.port == 0
        ) {
          return false;
        }
      } else {
        if (this.camera.name == "" || this.camera.serial_number == "") {
          return false;
        }
      }
      return true;
    },
    remove(id) {
      if (confirm(this.$t("camera.deleteConfirm")) == false) {
        return;
      }
      this.isLoading = true;
      this.$http
        .delete("camera", {
          params: {
            op: "delete_camera",
            id: id,
          },
        })
        .finally(() => {
          this.refreshState();
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.cameraConnectionTypes = [
      { name: "ONVIF", value: "onvif" },
      { name: "Natilon Pro", value: "natilon_pro" },
    ];
    (this.camera = {
      id: 0,
      name: "",
      ip: "",
      port: 0,
      username: "",
      password: "",
      channel: "",
      project_id: 0,
      project_name: "",
      company_id: 0,
      company_name: "",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      connection_type: "onvif", // "onvif" or "natilon_pro"
      serial_number: "",
    }),
      (this.isFormActive = false);
    this.selectedProjectId = this.camera.project_id;
    this.checkOnfivConnectionLoading = false;
    this.checkOnvifConnButtonTitle = this.$t(
      "camera.checkOnvifConnButtonTitle"
    );
    this.formValidation = true;
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
